import { Coordinate } from 'ol/coordinate'
import { ReactNode, useEffect, useMemo, useRef } from 'react'

import { StaticVessel as StaticVesselClass, StaticVesselStyle, ILonLat } from '@griegconnect/krakentools-kmap'

import { useMooringContext } from '../MooringPlanContext'
import { fromLonLat } from 'ol/proj'

type StaticVesselProps = {
  length: number
  width: number
  position: ILonLat
  rotation?: number
  onChange: (vesselId: string | number, position: Coordinate, rotation: number, isColliding?: boolean) => void
  editable?: boolean
  vesselId: string | number
  style: StaticVesselStyle
  selected?: boolean
  label?: ReactNode
}

export const StaticVessel = (props: StaticVesselProps) => {
  const { kmapInstance, mooringLayer } = useMooringContext()
  const ref = useRef<HTMLDivElement>(null)
  const staticVessel = useMemo(() => {
    const vessel = new StaticVesselClass(
      kmapInstance,
      props.length,
      props.width,
      fromLonLat([props.position.lon, props.position.lat]),
      props.rotation ?? 0,
      props.vesselId,
      props.onChange,
      props.style,
      !!props.editable,
      mooringLayer
    )
    return vessel
  }, [kmapInstance, props.vesselId])
  useEffect(
    () => () => {
      if (staticVessel) {
        staticVessel.destroy()
      }
    },
    [staticVessel]
  )

  useEffect(() => {
    if (props.onChange) {
      staticVessel.setOnChangeListener(props.onChange)
    }
  }, [props.onChange])

  useEffect(() => {
    staticVessel.setPosition(fromLonLat([props.position.lon, props.position.lat]))
  }, [props.position])

  useEffect(() => {
    staticVessel.setRotation(props.rotation ?? 0)
  }, [props.rotation])

  useEffect(() => {
    if (!props.editable) {
      staticVessel.setEditable(false)
      return
    }
    if (staticVessel) {
      staticVessel.setSelected(props.selected ?? false)
      return
    }
  }, [props.selected, props.editable])

  useEffect(() => {
    if (props.label) {
      if (ref.current) {
        staticVessel.setLabel(ref.current)
      }
    } else {
      staticVessel.removeLabelOverlay()
    }
  }, [ref, props.label])

  useEffect(
    () => () => {
      staticVessel.destroy()
    },
    [staticVessel]
  )

  return props.label ? (
    <div style={{ display: 'none' }}>
      <div ref={ref}>{props.label}</div>
    </div>
  ) : null
}
